import React from 'react';
import { Card, CardBody } from 'reactstrap';

import TravelTipsHeader from './TravelTipsHeaderComponent';

function FlightDeals(props) {
    return (
        <React.Fragment>
            <TravelTipsHeader image="https://live.staticflickr.com/65535/50380741782_712bd2de2b_o.jpg"
                thumb="https://live.staticflickr.com/65535/50380741782_f8517a2564_t.jpg"
                title="Flight Deals" subtitle="Travel Tips" />

            <div className="container">
                <div className="city-text">
                    <div className="col-12 col-lg-10 ms-auto me-auto" >
                        <p style={{ marginTop: '40px', marginBottom: '40px' }}>
                        One of the biggest expenses when you traveling to a far away destination are the flights.
                        We never buy a flight ticket without making some research beforehand in several websites.
                        Your chances of saving money on finding a cheaper flight improves a lot if you verify your flights in advance and often.
                        Here we are going to describe the best booking websites (according to our experience), and other helpful websites to help you find the best deals.
                        </p>

                        <Card className="col-11 col-md-8 m-auto col-lg-6 m-lg-5"
                            style={{ paddingTop: '10px', paddingLeft: '20px', borderRadius: '20px', backgroundColor: '#FAFAFA' }}>
                            <CardBody className="visas-country-list">
                                <ul className="list-unstyled" >
                                    <li style={{ marginBottom: '15px' }}>Flight Search</li>
                                </ul>
                                <ul>
                                    <a href="#skyscanner"><li style={{ marginBottom: '5px' }}>SkyScanner</li></a>
                                    <a href="#momondo"><li style={{ marginBottom: '5px' }}>Momondo</li></a>
                                    <a href="#googlefights"><li style={{ marginBottom: '5px' }}>Google Flights</li></a>
                                    <a href="#skiplagged"><li style={{ marginBottom: '5px' }}>Skiplagged</li></a>
                                </ul>
                                <ul className="list-unstyled">
                                    <li className="list-unstyled" style={{ marginBottom: '15px', marginTop: '15px' }}>Other Helpful Websites</li>
                                </ul>
                                <ul>
                                    <a href="#airhint"><li style={{ marginBottom: '5px' }}>AirHint</li></a>
                                    <a href="#secretflying"><li style={{ marginBottom: '5px'}}>Secret Flying</li></a>
                                </ul>
                            </CardBody>
                        </Card>
                    </div>


                    <div className="city-topic">
                        <div id="skyscanner" className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>SkyScanner</h1>
                            <a href="https://www.skyscanner.net" target="_blank" rel="noopener noreferrer">www.skyscanner.net</a>
                            <br/><br/>
                            <p>
                            This website is a very powerful search engine for flights.
                            You can search for flights within specific dates, but if you are undecided on when to go or want to choose the cheapest date, you can compare the prices between everyday of each month, and find when it is best to buy.
                            If have not decided where to go, you can also set an empty destination, and SkyScanner will show you many places where you can fly on a given date for cheap.
                            It is a must have tool, and you can also use their very friendly user mobile app.
                            <br/><br/>
                            This website is used best for short flights, and it is probably the best one for this.
                            For long haul flights, SkyScanner is sometimes not very accurate.
                            </p>
                        </div>
                    </div>

                    <div className="city-topic">
                        <div id="momondo" className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>Momondo</h1>
                            <a href="https://www.momondo.com" target="_blank" rel="noopener noreferrer">www.momondo.com</a>
                            <br/><br/>
                            <p>
                            Very similar to Skyscanner this search engine will also help you to find the best options at the best prices. It has
                            a friendly interface too, and you can create different routes using this.
                            </p>
                        </div>
                    </div>

                    <div className="city-topic">
                        <div id="googlefights" className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>Google Flights</h1>
                            <a href="https://www.google.com/flights" target="_blank" rel="noopener noreferrer">www.google.com/flights</a>
                            <br/><br/>
                            <p>
                            Another great search engine. Although it does not offer the same flexiblity as Skyscanner,
                            this website is much more accurate and as such it is best option to use for long haul flights.
                            </p>
                        </div>
                    </div>

                    <div className="city-topic">
                        <div id="skiplagged" className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>Skiplagged</h1>
                            <a href="https://skiplagged.com/" target="_blank" rel="noopener noreferrer">https://skiplagged.com</a>
                            <br/><br/>
                            <p>
                            This is a <b>fabulous</b> website to search for the cheapest flights possible between two locations.
                            <br/><br/>
                            Unlike the ones above, this website is not ideal if you are still uncertain of where to go or when, as every search you make here takes considerably more time than in the others.
                            On the upside, if you already are decided on where to go and the approximate dates, then Skiplagged can make wonders for you.
                            <br/><br/>
                            This website searches for a much more broaden number of possibilities to get you to your destinations, putting into calculation many more possible layovers, flight exchanges and more.
                            The result is more often than not better deals than the ones you can find on normal websites.
                            </p>
                        </div>
                    </div>

                    <div className="city-topic">
                        <div id="airhint" className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>AirHint</h1>
                            <a href="https://www.airhint.com/" target="_blank" rel="noopener noreferrer">www.airhint.com</a>
                            <br/><br/>
                            <p>
                            If you think your flight tickets are too expensive, or are buying the tickets with many months in advance, than you should give this website a try.
                            <br/><br/>
                            AirHint compares the actual price of a specific flight with its data on the historical flutuation of the flight price on the past years to give you an idea whether or not you should buy the tickets now or wait for a price drop.
                            <br/><br/>
                            Granted, this is not correct all the times, but it is good to have a "<i>data driven</i>" opinion on if a flight ticket is currently overpriced or not.
                            </p>
                        </div>
                    </div>

                    <div className="city-topic">
                        <div id="secretflying" className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>Secret Flying</h1>
                            <a href="https://www.secretflying.com" target="_blank" rel="noopener noreferrer">www.secretflying.com</a>
                            <br/><br/>
                            <p>
                            This website is not really a flight search engine. Rather, it shows many flights considerably cheaper than their usually are.
                            <br/><br/>
                            Be it because of an error fare, a promotion campaign, or just good generosity from the airline company, Secret Flying is constantly showing you great flight deals to grab.
                            <br/><br/>
                            If you want to plan for a next trip and don't know where, give Secret Flying a chance, you may just find your next destination.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default FlightDeals;
